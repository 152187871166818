import { Box, Stack } from "@mui/joy"
import React, { useEffect } from "react"
import { Events, Link, scrollSpy } from "react-scroll"
import "./Navigation.css"

const Navigation: React.FC = () => {
  useEffect(() => {
    Events.scrollEvent.register("begin", function () {
      console.log("begin", arguments)
    })

    Events.scrollEvent.register("end", function () {
      console.log("end", arguments)
    })

    scrollSpy.update()

    return () => {
      Events.scrollEvent.remove("begin")
      Events.scrollEvent.remove("end")
    }
  }, [])

  return (
    <nav className="navbar">
      <Stack flexDirection="row">
        {["home", "about", "portfolio", "contact"].map((section) => (
          <Box className="link" key={section}>
            <Link
              activeClass="active-link"
              duration={500}
              offset={-70}
              smooth={true}
              spy={true}
              to={section}
            >
              {section.charAt(0).toUpperCase() + section.slice(1)}
            </Link>
          </Box>
        ))}
      </Stack>
    </nav>
  )
}

export default Navigation
