import DownloadIcon from "@mui/icons-material/Download"
import { Box, Link, Paper, Stack, Typography } from "@mui/material"
import React from "react"
import AccordionCards from "../../Components/Accordion/AccordionCards"
import Certificates from "../../Components/Certificates/Certificates"
import Skills from "../../Components/Skills/Skills"
import "./About.css"

const styles = {
  wrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: {
      xs: "1rem",
      sm: "1rem",
      md: "0.5rem"
    }
  },

  aboutTextWrapper: {
    width: "100%",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: { xs: "2rem", sm: "4rem", md: "6rem" },
    paddingRight: { xs: "2rem", sm: "4rem", md: "3rem" },
    paddingBottom: { xs: "1rem", sm: "1rem", md: "3rem" }
  },
  downloadResumeButton: {
    display: "flex",
    alignItems: "center",
    color: "#5b4a44",
    textDecoration: "none",
    backgroundColor: "#e3dfdb",
    borderRadius: "30px",
    padding: { xs: "0.8rem", sm: "0.8rem", md: "1rem" },

    ":hover": {
      bgcolor: "#afa4a0"
    }
  },
  downloadResumeIcon: {
    fontSize: {
      xs: "1rem",
      sm: "1.5rem",
      md: "2rem"
    }
  },
  downloadResumeText: {
    fontSize: "calc(10px + 1vmin)",
    marginLeft: {
      xs: "0.3rem",
      sm: "0.4rem",
      md: "0.5rem"
    }
  }
}

const About: React.FC = () => (
  <Paper className="About">
    <Paper sx={styles.wrapper}>
      <Stack alignItems="center" color="#5b4a44">
        <Stack
          sx={{
            width: "100%",
            justifyContent: "center"
          }}
        >
          <div className="about-me-container">
            <div className="header-text-container">
              <p className="header-intro-text">LET ME SHARE A LITTLE</p>
              <Typography
                gutterBottom
                sx={{ fontSize: "calc(10px + 6vmin)" }}
                variant="h1"
              >
                About Me
              </Typography>
              <div className="line" />

              <Box
                sx={{
                  width: "100%",
                  display: { xs: "none", sm: "none", md: "block" }
                }}
              >
                <Stack
                  alignItems="center"
                  color="#5b4a44"
                  flexDirection="row"
                  justifyContent="center"
                  padding={{ xs: "0.5rem", sm: "0.5rem", md: "1rem" }}
                >
                  <img
                    alt="Saratov State University named after N.G.Chernyshevsky logo"
                    loading="lazy"
                    src={`${process.env.PUBLIC_URL}/sgu.jpeg`}
                  />

                  <Stack marginLeft="1rem">
                    <Typography fontSize="calc(10px + 1vmin)" fontWeight="bold">
                      Master&apos;s degree Physics and Computer Science
                    </Typography>

                    <Typography fontSize="calc(10px + 1vmin)">
                      Saratov State University
                    </Typography>
                    <Typography fontSize="calc(10px + 0.8vmin)">
                      2004-2009
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
            </div>
          </div>
        </Stack>

        <Stack sx={styles.aboutTextWrapper}>
          <Box width="100%">
            <Typography
              color="text.secondary"
              sx={{
                fontSize: "calc(10px + 1vmin)",
                padding: { xs: "1rem", sm: "1rem", md: "2rem" }
              }}
              variant="subtitle1"
            >
              <p>Welcome to my portfolio!</p>
              <p>
                I&apos;m Aleksandra a Front-end engineer based in New York City
                Metropolitan Area. Here you will find information about me, what
                I do, my education, projects, skills and also about my career
                transition from QA Engineering to Front-End Development
              </p>

              <p>
                I believe our memories are closely tied to emotions, especially
                those filled with excitement. I still remember the moment when I
                successfully developed and launched my first end-to-end test. It
                was almost like magic to watch my script autonomously open a
                browser, enter login credentials, and then go through the login
                process!
              </p>
              <p>
                In Front-End Development, I found an opportunity not just to
                test products but also to create them. This inspired me to begin
                studying Front-End web technologies.
              </p>
              <p>
                Now, I am deeply fascinated by the process of creating software
                products and understanding the technology behind them.
              </p>
              <p>I want to bring ideas to life!</p>
            </Typography>
            <Stack alignItems="center" width="100%">
              <Paper
                sx={{
                  width: { xs: "100%", sm: "100%", md: "40%" }
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: { xs: "block", sm: "block", md: "none" }
                  }}
                >
                  <Stack
                    alignItems="center"
                    color="#5b4a44"
                    flexDirection="row"
                    justifyContent="center"
                    padding={{ xs: "0.5rem", sm: "0.5rem", md: "1rem" }}
                  >
                    <img
                      alt="Saratov State University named after N.G.Chernyshevsky logo"
                      loading="lazy"
                      src={`${process.env.PUBLIC_URL}/sgu.jpeg`}
                    />

                    <Stack marginLeft="1rem">
                      <Typography
                        fontSize="calc(10px + 1vmin)"
                        fontWeight="bold"
                      >
                        Master&apos;s degree Physics and Computer Science
                      </Typography>

                      <Typography fontSize="calc(10px + 1vmin)">
                        Saratov State University
                      </Typography>
                      <Typography fontSize="calc(10px + 0.8vmin)">
                        2004-2009
                      </Typography>
                    </Stack>
                  </Stack>
                </Box>
                <AccordionCards />
              </Paper>
            </Stack>
          </Box>
        </Stack>
        <Stack
          alignItems="center"
          justifyContent="center"
          paddingBottom={{ xs: "0.5rem", sm: "1rem", md: "2rem" }}
        >
          <Link
            href="https://drive.google.com/file/d/1x-xd56OJfIBN4oRDY4NXH7_WdTWnl03L/view?usp=sharing"
            rel="noopener noreferrer"
            sx={styles.downloadResumeButton}
            target="_blank"
          >
            <DownloadIcon sx={styles.downloadResumeIcon} />
            <Typography sx={styles.downloadResumeText}>RESUME</Typography>
          </Link>
        </Stack>
        <Skills />
        <Certificates />
      </Stack>
    </Paper>
  </Paper>
)

export default About
