/* eslint-disable react/no-unescaped-entities */
import React from "react"
import "./App.css"
import Main from "./Sections/Main/Main"

const App: React.FC = () => (
  <div>
    <Main />
  </div>
)

export default App
