import React from "react"
import { Element } from "react-scroll"
import About from "../About/About"
import Contact from "../Contact/Contact"
import Home from "../Home/Home"
import Portfolio from "../Portfolio/Portfolio"

const Main: React.FC = () => (
  <main className="Main">
    <Element className="section" name="home">
      <Home />
    </Element>
    <Element className="section" name="about">
      <About />
    </Element>
    <Element className="section" name="portfolio">
      <Portfolio />
    </Element>
    <Element className="section" name="contact">
      <Contact />
    </Element>
  </main>
)

export default Main
