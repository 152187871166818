import { Box, ListItemAvatar, Stack, Typography } from "@mui/material"
import { useInView } from "react-intersection-observer"
import "./Skills.css"

export default function Skills() {
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.1
  })

  return (
    <Box
      ref={ref}
      sx={{
        paddingTop: "2rem",
        backgroundColor: "#faf9f8",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyItems: "center",
        flexDirection: "column"
      }}
    >
      <Typography
        gutterBottom
        sx={{
          fontSize: "calc(10px + 2vmin)",
          textAlign: "center",
          padding: "2rem"
        }}
        variant="h4"
      >
        SKILLS
      </Typography>

      <Stack
        flexDirection={{ xs: "column", sm: "row", md: "row" }}
        paddingBottom="2rem"
      >
        <Stack
          className={inView ? "slide-in-right" : ""}
          flexDirection="row"
          marginTop="0.5rem"
        >
          {itemFirstRow.map((itemFirstRow) => (
            <Stack
              alignItems="center"
              key={itemFirstRow.title}
              padding={{ xs: "0px", sm: "0px", md: "0.5rem" }}
            >
              <ListItemAvatar>
                <img
                  alt={`${itemFirstRow.title} Icon`}
                  src={`https://skillicons.dev/icons?i=${itemFirstRow.title}&theme=light`}
                />
              </ListItemAvatar>
              <Typography style={{ textTransform: "uppercase" }}>
                {itemFirstRow.title}
              </Typography>
            </Stack>
          ))}
        </Stack>
        <Stack
          className={inView ? "slide-in-right" : ""}
          flexDirection="row"
          marginTop="0.5rem"
        >
          {itemSecondRow.map((itemSecondRow) => (
            <Stack
              alignItems="center"
              key={itemSecondRow.title}
              padding={{ xs: "0px", sm: "0px", md: "0.5rem" }}
            >
              <ListItemAvatar>
                <img
                  alt={`${itemSecondRow.title} Icon`}
                  src={`https://skillicons.dev/icons?i=${itemSecondRow.title}&theme=light`}
                />
              </ListItemAvatar>
              <Typography style={{ textTransform: "uppercase" }}>
                {itemSecondRow.title}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Box>
  )
}

const itemFirstRow = [
  {
    title: "html"
  },
  {
    title: "css"
  },
  {
    title: "js"
  },
  {
    title: "react"
  },
  {
    title: "ts"
  }
]

const itemSecondRow = [
  {
    title: "redux"
  },
  {
    title: "mui"
  },
  {
    title: "git"
  },
  {
    title: "github"
  },
  {
    title: "jest"
  }
]
