import { Box, Grid, ListItem, ListItemButton, Typography } from "@mui/material"

const styles = {
  certificatesContaner: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: { xs: "1rem", sm: "2rem", md: "3rem" },
    color: "#5b4a44",
    fontSize: "calc(10px + 1vmin)",
    backgroundColor: "#e7f1fb",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat"
  },
  link: {
    textDecoration: "none",
    fontSize: "1.5em",
    width: "100%"
  },
  educationCard: {
    width: "100%",
    marginBottom: "1rem",
    padding: "1rem"
  }
}

interface CertificateItemProps {
  certificate: {
    title: string
    url: string
    imageName: string
  }
}

export default function Certificates() {
  return (
    <Box sx={styles.certificatesContaner}>
      <Typography
        gutterBottom
        sx={{
          fontSize: "calc(10px + 2vmin)",
          textAlign: "center",
          padding: "2rem"
        }}
        variant="h4"
      >
        CERTIFICATES
      </Typography>
      <Grid container spacing={2}>
        <Grid item md={4} xs={12}>
          {firstRowData.map((certificate) => (
            <CertificateItem
              certificate={certificate}
              key={certificate.title}
            />
          ))}
        </Grid>
        <Grid item md={4} xs={12}>
          {secondRowData.map((certificate) => (
            <CertificateItem
              certificate={certificate}
              key={certificate.title}
            />
          ))}
        </Grid>
        <Grid item md={4} xs={12}>
          {thirdRowData.map((certificate) => (
            <CertificateItem
              certificate={certificate}
              key={certificate.title}
            />
          ))}
        </Grid>
      </Grid>
    </Box>
  )
}

function CertificateItem({ certificate }: CertificateItemProps) {
  return (
    <ListItem className={`hover-image hover-${certificate.imageName}`}>
      <a
        href={certificate.url}
        rel="noopener noreferrer"
        style={styles.link}
        target="_blank"
      >
        <ListItemButton>
          <img
            alt={certificate.title}
            loading="lazy"
            src={`${process.env.PUBLIC_URL}/${certificate.imageName}.png`}
            style={{ height: "auto", width: "100%" }}
          />
        </ListItemButton>
      </a>
    </ListItem>
  )
}

const firstRowData = [
  {
    title: "HTML Certificate",
    url: "https://www.codecademy.com/profiles/devSurfer66886/certificates/9eb0741e5ebef1f9f58a53bfac67d3a7",
    imageName: "html"
  },
  {
    title: "CSS Certificate",
    url: "https://www.codecademy.com/profiles/devSurfer66886/certificates/9a5bb1fc45b4281af1fffec93b0aaf05",
    imageName: "css"
  },
  {
    title: "JavaScript Certificate",
    url: "https://www.codecademy.com/profiles/devSurfer66886/certificates/705dcb15de0da4dd9d9fc4f3274b430e",
    imageName: "js"
  }
]

const secondRowData = [
  {
    title: "React Certificate",
    url: "https://www.codecademy.com/profiles/devSurfer66886/certificates/af00e5032d0a68cc84879983f5d8333b",
    imageName: "react"
  },
  {
    title: "TypeScript Certificate",
    url: "https://www.codecademy.com/profiles/devSurfer66886/certificates/56fb1e71303e37b643bb1905f31c8a09",
    imageName: "typeScript"
  },
  {
    title: "Redux Certificate",
    url: "https://www.codecademy.com/profiles/devSurfer66886/certificates/7bec2ab40c4dcf741cd2ff8d50a35e2d",
    imageName: "redux"
  }
]

const thirdRowData = [
  {
    title: "Front-end Certificate",
    url: "https://www.codecademy.com/profiles/devSurfer66886/certificates/2682884a0719474f96407efe432fdd87",
    imageName: "frontEnd"
  },
  {
    title: "Git Certificate",
    url: "https://www.codecademy.com/profiles/devSurfer66886/certificates/a8ab218d5950c29861635cc0bf12fd13",
    imageName: "git"
  },

  {
    title: "Build Website Certificate",
    url: "https://www.codecademy.com/profiles/devSurfer66886/certificates/36ae898a1d1c8524815305b2d1d2ebab",
    imageName: "buildWebsite"
  }
]
