import GitHubIcon from "@mui/icons-material/GitHub"
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined"
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Stack,
  Typography
} from "@mui/material"
import "./PortfolioGrid.css"

const styles = {
  link: {
    color: "#498edc",
    fontSize: "calc(10px + 1vmin)",
    textDecoration: "none",
    ":hover": {
      textDecoration: "underlined"
    }
  },
  portfolioIcon: {
    fontSize: {
      xs: "small",
      sm: "medium",
      md: "large"
    }
  },
  portfolioLinkText: {
    fontSize: "calc(8px + 1vmin)",
    marginLeft: {
      xs: "0.3rem",
      sm: "0.4rem",
      md: "0.5rem"
    }
  },
  portfolioTechnologies: {
    fontSize: "calc(8px + 0.6vmin)"
  }
}

export default function ProjectsGrid() {
  return (
    <Box
      sx={{
        width: "100%",
        mx: "100%",
        display: "flex",
        padding: { xs: "1.5rem", sm: "1.5rem", md: "3rem" },
        flexDirection: "column",
        backgroundColor: "#e7f1fb",
        color: "#5b4a44"
      }}
    >
      <Typography
        gutterBottom
        sx={{
          textAlign: "center",
          paddingBottom: { xs: "1rem", sm: "1rem", md: "2rem" }
        }}
        variant="h4"
      >
        MY PROJECTS
      </Typography>
      <Grid alignItems="stretch" container justifyContent="center" spacing={6}>
        {projectData.map((project, index) => (
          <Grid item key={index} md={4} sm={6} xs={12}>
            <Card
              className="CardContaner"
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: "100%",
                height: "100%",
                backgroundColor: "#f8fbfe"
              }}
            >
              <Box height="80%">
                <CardMedia
                  alt={project.altText}
                  component="img"
                  image={project.imageUrl}
                  style={{
                    width: "100%",
                    objectFit: "cover",
                    height: "300"
                  }}
                />
              </Box>
              <CardContent>
                <Typography
                  color="#5b4a44"
                  component="div"
                  gutterBottom
                  variant="h5"
                >
                  {project.title}
                </Typography>
                <Typography color="#5b4a44" variant="body2">
                  {project.description}
                </Typography>
                <Typography color="#5b4a44" paddingTop="1rem" variant="body2">
                  {project.purpose}
                </Typography>
              </CardContent>
              <CardActions>
                <Stack
                  flexDirection="row"
                  justifyContent="space-between"
                  padding="0.5rem"
                  width="100%"
                >
                  <a
                    href={project.liveUrl}
                    rel="noopener noreferrer"
                    style={styles.link}
                    target="_blank"
                  >
                    <Stack alignItems="center" flexDirection="row">
                      <LaunchOutlinedIcon sx={styles.portfolioIcon} />
                      <Typography sx={styles.portfolioLinkText}>
                        Live Version
                      </Typography>
                    </Stack>
                  </a>
                  <a
                    href={project.sourceCodeUrl}
                    rel="noopener noreferrer"
                    style={styles.link}
                    target="_blank"
                  >
                    <Stack alignItems="center" flexDirection="row">
                      <GitHubIcon sx={styles.portfolioIcon} />
                      <Typography sx={styles.portfolioLinkText}>
                        Source Code
                      </Typography>
                    </Stack>
                  </a>
                </Stack>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

const projectData = [
  {
    title: "MiniReddit",
    description:
      "MiniReddit offers a simplified Reddit browsing experience, that allows users to browse posts just as they would normally, but without ads and excluding certain features.",
    purpose:
      "The purpose of this project was to apply my knowledge in creating a modern front-end application using data from the third-party Reddit API. This application has a responsive design, and you can switch between Dark Mode and Light Mode in the Settings.",
    imageUrl: `${process.env.PUBLIC_URL}/miniReddit.png`,
    altText: "MiniReddit preview",
    liveUrl: "https://mini-reddit.aleksandra.lysachok.dev/",
    sourceCodeUrl: "https://github.com/alysachok/MiniReddit"
  },
  {
    title: "Quick List",
    description:
      "The Quick List App is an intuitive to-do list manager that enables users to easily organize their daily tasks. This app utilizes local storage to store data in a JSON format directly within the user's browser, providing an immediate and persistent experience across sessions without the need for server-side interaction.",
    purpose:
      "Creating this project allowed me to practice CRUD (Create, Read, Update, Delete) data operations and manage the application state on the front end.",
    imageUrl: `${process.env.PUBLIC_URL}/quickList.png`,
    altText: "Quick List preview",
    liveUrl: "https://quicklist.aleksandra.lysachok.dev/",
    sourceCodeUrl: "https://github.com/alysachok/quickList"
  },
  {
    title: "Countries",
    description:
      "Countries is a dashboard that allows users to quickly sort and filter countries based on various parameters, such as population, capital, and area, among others.",
    purpose:
      "The purpose of this application is to leverage my knowledge to present large datasets in a structured format, utilizing Material-UI components and third-party APIs. This app is designed exclusively for desktop devices.",
    imageUrl: `${process.env.PUBLIC_URL}/countries.png`,
    altText: "Countries preview",
    liveUrl: "https://countries.aleksandra.lysachok.dev/",
    sourceCodeUrl: "https://github.com/alysachok/Countries"
  }
]
