import { Box, Typography } from "@mui/material"
import Accordion from "./Accordion"

export default function AccordionCards() {
  return (
    <Box>
      <Accordion
        details={
          <Typography color="#5b4a44">
            <h4>Cultural Background</h4>
            <p>
              While my full name is Aleksandra, often called Sasha—a nickname
              that sometimes surprises those unfamiliar with the traditional
              Slavic diminutive
            </p>
            <p>Born in Russia, fan of Turkish coffee, live in USA</p>
            <h4>Reading Interests</h4>
            <p>
              I enjoy reading personal development and self-improvement books. I
              mix them with novels and the inspirational journeys found in
              biographie
            </p>
            <h4>Fitness Achievements</h4>
            <p>
              I consider myself a bit of an fitness addict. My recent
              accomplishments include:
              <ul>
                <li>Completing a 10-minute plank challenge</li>
                <li>Finished a 100-day streak of doing 100 pushups daily</li>
                <li>Achieving 10 consecutive pull-ups</li>
              </ul>
              Planning my next fitness challenge!
            </p>
            <h4>Life Philosophy</h4>
            <p>
              My favorite principle for personal and professional growth is that
              small steps will lead you to your goals. Consistency is the key!
            </p>
          </Typography>
        }
        summary="My Personal Interests"
      />
    </Box>
  )
}
