import { Box, Paper, Stack, Typography } from "@mui/material"
import React from "react"
import PortfolioGrid from "../../Components/ProjectCards/PortfolioGrid"

const styles = {
  portfolioContainer: {
    margin: 0,
    width: "100%",
    minHeight: "100vh",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#5b4a44",
    backgroundColor: "#faf9f8"
  },
  portfolioTextWrapper: {
    width: "90%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: { xs: "0.5rem", sm: "1rem", md: "3rem" }
  },
  portfolioText: {
    fontSize: "calc(10px + 1vmin)",
    padding: "1rem"
  }
}

const Portfolio: React.FC = () => (
  <Paper sx={styles.portfolioContainer}>
    <Stack
      sx={{
        width: "100%",
        justifyContent: "center",
        paddingTop: { xs: "2rem", sm: "3rem", md: "4rem" }
      }}
    >
      <Box>
        <div className="header-text-container">
          <p className="header-intro-text">CHECK MY LATEST PROJECTS</p>
          <Typography
            gutterBottom
            sx={{ fontSize: "calc(10px + 6vmin)" }}
            variant="h1"
          >
            Portfolio
          </Typography>
          <div className="line" />
        </div>
      </Box>
    </Stack>
    <Box sx={styles.portfolioTextWrapper}>
      <Typography
        color="text.secondary"
        sx={styles.portfolioText}
        variant="subtitle1"
      >
        Throughout my learning journey in the Front-End Engineer courses at
        Codecademy, I have completed over 80 projects. Below, I present three of
        my most recent ones, each demonstrating a broad range of technologies.
        Developed from scratch, these projects utilize HTML, CSS, React,
        TypeScript, and Material-UI, with a focus on responsive design. Some
        incorporate additional tools such as React Router, RTK Query, and
        third-party APIs
      </Typography>
      <Typography
        color="text.secondary"
        sx={styles.portfolioText}
        variant="subtitle1"
      >
        Additionally, I have created this site as a centralized resource to
        highlight my professional background and achievements. The portfolio
        site is built with React and TypeScript, ensuring responsiveness from
        any device for those interested in my work.
      </Typography>
    </Box>
    <PortfolioGrid />
  </Paper>
)

export default Portfolio
