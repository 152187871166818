import EmailIcon from "@mui/icons-material/Email"
import GitHubIcon from "@mui/icons-material/GitHub"
import LinkedInIcon from "@mui/icons-material/LinkedIn"
import { Box, Link, Paper, Stack, Typography } from "@mui/material"
import React from "react"

const styles = {
  contact: {
    textAlign: "center",
    minHeight: "95vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#faf9f8",
    margin: 0,
    overflow: "hidden",
    color: "#5b4a44",
    width: "100%"
  },
  contactLink: {
    display: "flex",
    textDecoration: "none",
    alignItems: "center",
    color: "#5b4a44",
    padding: { xs: "0.5rem", sm: "0.5rem", md: "1rem" },

    ":hover": {
      transition: "color 0.3s ease-in-out",
      bgcolor: "rgba(227, 223, 219, 0.80)",
      textDecoration: "underlined",
      textDecorationColor: "#5b4a44",
      borderRadius: "30px"
    }
  },

  lineContactPage: {
    borderTop: "2px solid #5b4a44",
    width: "50%",
    margin: "0 auto",
    paddingBottom: { xs: "2rem", sm: "4rem", md: "6rem" }
  },

  headerTextContactPage: {
    fontSize: "calc(10px + 1vmin)",
    paddingLeft: { xs: "2rem", sm: "4rem", md: "6rem" },
    paddingRight: { xs: "2rem", sm: "4rem", md: "6rem" },
    paddingTop: "0px"
  },
  contactLinkIcon: {
    fontSize: {
      xs: "1rem",
      sm: "1.5rem",
      md: "2rem"
    }
  },
  contactLinkText: {
    fontSize: "calc(10px + 1vmin)",
    marginLeft: {
      xs: "0.3rem",
      sm: "0.4rem",
      md: "0.5rem"
    }
  },
  photoInfoContaner: {
    alignItems: "center",
    flexDirection: { xs: "column", sm: "column", md: "row" },
    justifyContent: "space-between",
    paddingLeft: { xs: "2rem", sm: "4rem", md: "6rem" },
    paddingRight: { xs: "2rem", sm: "4rem", md: "6rem" },
    padding: { xs: "1rem", sm: "1rem", md: "2rem" },
    marginLeft: { xs: "0rem", sm: "0rem", md: "10rem" }
  },

  contactInfoContaner: {
    alignItems: { xs: "center", sm: "center", md: "start" },
    flexDirection: "column",
    justifyItems: "center",
    width: "100%",
    marginLeft: { xs: "0rem", sm: "0rem", md: "10rem" }
  },
  contactInfoTitle: {
    color: "#5b4a44",
    fontSize: "calc(10px + 1vmin)",
    letterSpacing: { xs: "0.3px", sm: "0.5px", md: "1px" },
    paddingBottom: { xs: "0.5rem", sm: "1rem", md: "2rem" },
    paddingLeft: { xs: "0.5rem", sm: "1rem", md: "2rem" }
  },
  contactFooter: {
    bgcolor: "rgba(227, 223, 219, 0.80)",
    width: "100%",
    padding: {
      xs: "1rem",
      sm: "1.5rem",
      md: "2rem"
    }
  }
}

const ContactPage: React.FC = () => (
  <Paper sx={styles.contact}>
    <Stack>
      <Box paddingTop={{ xs: "2rem", sm: "3rem", md: "4rem" }}>
        <Typography
          gutterBottom
          sx={{ fontSize: "calc(10px + 6vmin)" }}
          variant="h1"
        >
          Let&apos;s Connect
        </Typography>
        <Box sx={styles.lineContactPage} />
      </Box>
      <Typography sx={styles.headerTextContactPage} variant="subtitle1">
        I&apos;m open to work opportunities, happy to discussing new projects
        and creative ideas
      </Typography>
      <Typography sx={styles.headerTextContactPage} variant="subtitle1">
        Feel free to contact me using any of the methods below
      </Typography>
      <Stack sx={styles.photoInfoContaner}>
        <Box width={{ xs: "15rem", sm: "20rem", md: "50rem" }}>
          <img
            alt="Aleksandra Lysachok"
            src={`${process.env.PUBLIC_URL}/photoContactPage.png`}
            width="100%"
          />
        </Box>

        <Stack sx={styles.contactInfoContaner}>
          <Typography sx={styles.contactInfoTitle}>
            CONTACT INFORMATION
          </Typography>
          <Link
            href="mailto:aleksandra@lysachok.dev"
            sx={styles.contactLink}
            target="_blank"
          >
            <EmailIcon sx={styles.contactLinkIcon} />
            <Typography sx={styles.contactLinkText}>
              aleksandra@lysachok.dev
            </Typography>
          </Link>
          <Link
            href="https://www.linkedin.com/in/aleksandra-lysachok-33897b1b9/"
            rel="noopener noreferrer"
            sx={styles.contactLink}
            target="_blank"
          >
            <LinkedInIcon sx={styles.contactLinkIcon} />
            <Typography sx={styles.contactLinkText}>LinkedIn</Typography>
          </Link>
          <Link
            href="https://github.com/alysachok"
            rel="noopener noreferrer"
            sx={styles.contactLink}
            target="_blank"
          >
            <GitHubIcon sx={styles.contactLinkIcon} />
            <Typography sx={styles.contactLinkText}>GitHub</Typography>
          </Link>
        </Stack>
      </Stack>
    </Stack>

    <Box mt={3} sx={styles.contactFooter}>
      <Typography align="center" color="#5b4a44" fontSize="calc(7px + 1vmin)">
        © {new Date().getFullYear()} Aleksandra. All Rights Reserved.
      </Typography>
    </Box>
  </Paper>
)

export default ContactPage
