import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Box, Typography } from "@mui/material"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import React, { useState } from "react"
interface AccordionTransitionProps {
  summary: string
  details: React.ReactNode
}

const AccordionTransition: React.FC<AccordionTransitionProps> = ({
  summary,
  details
}) => {
  const [expanded, setExpanded] = useState(false)

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded)
  }

  return (
    <Box>
      <Accordion
        TransitionProps={{ timeout: 200 }}
        expanded={expanded}
        onChange={handleExpansion}
        sx={{
          "& .MuiAccordion-region": {
            height: expanded ? "auto" : 0
          },
          "& .MuiAccordionDetails-root": {
            display: expanded ? "block" : "none"
          }
        }}
      >
        <AccordionSummary
          aria-controls="panel1-content"
          expandIcon={<ExpandMoreIcon />}
          id="panel1-header"
        >
          <Typography color="#5b4a44" variant="h6">
            {summary}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>{details}</AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default AccordionTransition
