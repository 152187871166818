/* eslint-disable react/no-unescaped-entities */
import DownloadIcon from "@mui/icons-material/Download"
import GitHubIcon from "@mui/icons-material/GitHub"
import LinkedInIcon from "@mui/icons-material/LinkedIn"
import LocationOnIcon from "@mui/icons-material/LocationOn"
import { Box, Link, Stack, Typography } from "@mui/material"
import React from "react"
import Navigation from "../../Components/Navigation/Navigation"
import "./Home.css"

const styles = {
  homeText: {
    opacity: 0,
    transform: "translateX(50px)",
    animation: "appear 0.5s ease-out 4.5s forwards",
    fontSize: "calc(10px + 1vmin)",
    color: "#5b4a44"
  },

  homeLink: {
    display: "flex",
    alignItems: "center",
    color: "#5b4a44",
    textDecoration: "none",
    padding: "0.5rem",

    ":hover": {
      transition: "color 0.3s ease-in-out",
      bgcolor: "rgba(227, 223, 219, 0.80)",
      textDecoration: "underlined",
      textDecorationColor: "#5b4a44",
      borderRadius: "30px"
    }
  }
}

const Home: React.FC = () => (
  <Box className="Home">
    <Box>
      <Navigation />
      <Box className="Header-mobile">
        <Box width="80%">
          <img
            alt="Aleksandra Lysachok"
            src={`${process.env.PUBLIC_URL}/photoHomePage.png`}
          />
        </Box>
      </Box>
    </Box>
    <Box className="Home-main">
      <Box className="Home-desktop-img">
        <img
          alt="Aleksandra Lysachok"
          src={`${process.env.PUBLIC_URL}/photoHomePage.png`}
          width="700px"
        />
      </Box>
      <Box className="Home-greeting-contaner">
        <div className="typewriter">
          <Typography gutterBottom variant="h1">
            Hi! I'm Aleksandra
          </Typography>
          <p>Front-end Engineer</p>
        </div>
        <Box sx={styles.homeText}>
          <p> Passionate about modern web technologies</p>
          <p>
            Specializing in React and TypeScript for single-page applications
          </p>
          <p> Focused on creating user-friendly interfaces</p>
        </Box>
      </Box>
    </Box>
    <Box className="Footer">
      <Stack
        alignItems="center"
        flexDirection="row"
        justifyContent="start"
        width="50%"
      >
        <Link
          href="https://www.linkedin.com/in/aleksandra-lysachok-33897b1b9/"
          rel="noopener noreferrer"
          sx={styles.homeLink}
          target="_blank"
        >
          <LinkedInIcon />
          <Typography marginLeft="0.3rem" marginRight="2rem">
            LinkedIn
          </Typography>
        </Link>
        <Link
          href="https://github.com/alysachok"
          rel="noopener noreferrer"
          sx={styles.homeLink}
          target="_blank"
        >
          <GitHubIcon />
          <Typography marginLeft="0.3rem" marginRight="2rem">
            GitHub
          </Typography>
        </Link>
        <Link
          href="https://drive.google.com/file/d/1x-xd56OJfIBN4oRDY4NXH7_WdTWnl03L/view?usp=sharing"
          rel="noopener noreferrer"
          sx={styles.homeLink}
          target="_blank"
        >
          <DownloadIcon />
          <Typography marginLeft="0.3rem">Resume</Typography>
        </Link>
      </Stack>
      <Stack alignItems="center" flexDirection="row">
        <LocationOnIcon />
        <Typography>New York City Metropolitan Area</Typography>
      </Stack>
    </Box>
    <Box className="Footer-mobile">
      <Stack flexDirection="row">
        <Link
          href="https://www.linkedin.com/in/aleksandra-lysachok-33897b1b9/"
          rel="noopener noreferrer"
          sx={styles.homeLink}
          target="_blank"
        >
          <LinkedInIcon fontSize="small" />
        </Link>
        <Link
          href="https://github.com/alysachok"
          rel="noopener noreferrer"
          sx={styles.homeLink}
          target="_blank"
        >
          <GitHubIcon fontSize="small" />
        </Link>
      </Stack>
      <Stack alignItems="center" flexDirection="row">
        <LocationOnIcon fontSize="small" />
        <Typography fontSize="calc(10px + 1vmin)">
          New York City Area
        </Typography>
      </Stack>
    </Box>
  </Box>
)

export default Home
